import React, { useState } from "react";
import {
  Button,
  SignupModal,
} from "components";
import { ARROW_ICONS, } from "utils/constants";

function SignUpButton({ customStyle }) {
  const [isShowWhiteListModal, setShowWhiteListModal] = useState(false);

  const bodyElement = document.body;
  isShowWhiteListModal ? bodyElement.classList.add("overflow-hidden")
    : bodyElement.classList.remove("overflow-hidden");

  return (
    <>
      <Button
        className={customStyle}
        onClick={() => setShowWhiteListModal(true)}
      >
        Sign up for Whitelist
        <span className={`ml-2 w-4 h-4 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}>
          {ARROW_ICONS.circleArrow}
        </span>
      </Button>

      {isShowWhiteListModal && (
        <SignupModal
          isToggled={isShowWhiteListModal}
          setToggled={() => setShowWhiteListModal(!isShowWhiteListModal)}
        />
      )}
    </>
  );
}

export default SignUpButton;
