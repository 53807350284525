import React from "react";
import { Footer, Header } from "components";

function Layout({ children }) {
  return (
    <>
      <Header />
      <main className="relative w-full h-auto">{children}</main>
      <Footer />
    </>
  );
}

export default Layout;
