import React, { useState } from "react";
import { Button, Container, SignupModal, SplineViewer } from "components";
import { ARROW_ICONS, INFECTIVlOGOS } from "utils/constants";
import { useMediaQuery } from "hooks/useMediaQuery";

function VerifierNode() {
  const [isShowWhiteListModal, setShowWhiteListModal] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const bodyElement = document.body;
  isShowWhiteListModal ? bodyElement.classList.add("overflow-hidden")
    : bodyElement.classList.remove("overflow-hidden");

  return (
    <>
      <section className="relative w-full py-[30px] md:py-16 laptop:pt-[150px] laptop:pb-[75px]" id="verfiedSection">
        <Container>
          <div className="relative flex flex-col md:flex-row md:items-end gap-4 md:gap-0 borderBottom">
            <div className="flex-1 max-w-full laptop:max-w-[60%]">
              <div className="flex flex-col items-start xl:items-end laptop:flex-row gap-5 xxl:gap-0">
                <h3 className="text-xxl md:text-1xl xl:text-2xl leading-10 -tracking-11 font-normal capitalize xxl:border-r-[1px] border-gray/30 pr-[26px]">
                  Verifier node sale.
                </h3>

                <div className="flex items-start lg:items-center flex-col md:flex-row xxl:ml-[26px] gap-4 laptop:gap-5 xl:gap-[22px]">
                  <div className="relative flex md:flex-col-reverse laptop:items-end gap-2">
                    <div className="flex items-center gap-2">
                      <span className="w-4 h-4 *:w-full *:h-full">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.3398 9.38587C15.7825 6.47153 16.4058 2.41167 16.3384 1.18192C16.3384 1.04716 16.271 0.929236 16.1868 0.828161C16.1026 0.743932 15.9846 0.676548 15.833 0.676548C14.6033 0.609164 10.5434 1.21562 7.64593 3.65827L6.9721 3.40558C5.79289 2.96759 4.46206 3.32135 3.65346 4.28157L1.78356 6.48838C1.51403 6.7916 1.6488 7.28014 2.03625 7.4149L4.04091 8.15612C3.60292 8.89734 3.29969 9.52064 3.09754 9.95863C2.92908 10.3124 3.01331 10.7335 3.29969 11.0199L5.99504 13.7321C6.26457 14.0016 6.68572 14.0859 7.05633 13.9174C7.49432 13.7153 8.11762 13.412 8.85884 12.974L9.58321 14.9787C9.71798 15.3662 10.2065 15.4841 10.5097 15.2314L12.6997 13.3615C13.6599 12.5529 14.0137 11.2221 13.5757 10.0429L13.3398 9.38587ZM12.0764 7.28014C11.4363 7.92028 10.3918 7.92028 9.73482 7.28014C9.09468 6.63999 9.09468 5.59555 9.73482 4.93856C10.375 4.29841 11.4194 4.29841 12.0764 4.93856C12.7165 5.59555 12.7165 6.63999 12.0764 7.28014Z"
                            fill="#0B0B0B"
                          />
                          <path
                            d="M4.83315 14.4231L3.62024 15.6192C3.3844 15.855 3.3844 16.2593 3.62024 16.4952C3.85608 16.731 4.26039 16.731 4.49623 16.4952L5.70913 15.2823C5.94497 15.0464 5.94497 14.6421 5.70913 14.4063C5.45644 14.1704 5.06899 14.1704 4.83315 14.4231Z"
                            fill="#0B0B0B"
                          />
                          <path
                            d="M4.14127 12.8732C3.90542 12.6373 3.50112 12.6373 3.26528 12.8732L1.34485 14.8104C1.10901 15.0463 1.10901 15.4506 1.34485 15.6864C1.58069 15.9223 1.98499 15.9223 2.22084 15.6864L4.14127 13.7323C4.39396 13.4965 4.39396 13.109 4.14127 12.8732Z"
                            fill="#0B0B0B"
                          />
                          <path
                            d="M2.60952 12.1991C2.84536 11.9633 2.84536 11.559 2.60952 11.3231C2.37368 11.0873 1.96938 11.0873 1.73354 11.3231L0.520632 12.536C0.284789 12.7719 0.284789 13.1762 0.520632 13.412C0.756474 13.6479 1.16078 13.6479 1.39662 13.412L2.60952 12.1991Z"
                            fill="#0B0B0B"
                          />
                        </svg>
                      </span>
                      <h5 className="text-base text-black leading-[18px] -tracking-05 font-semibold capitalize">
                        Whitelist sale launch
                      </h5>
                    </div>
                    <div className="bg-orange rounded-full py-[2px] px-[14px] text-[10px] font-semibold -tracking-08 text-black w-fit">
                      Coming Soon
                    </div>
                  </div>

                  <div className="relative flex md:flex-col-reverse laptop:items-end gap-2">
                    <div className="flex items-center gap-2">
                      <span className="w-4 h-4 *:w-full *:h-full">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.1469 16.3323C16.9639 17.9645 15.2996 19.1849 13.388 19.8226C11.4755 20.4601 9.41136 20.4824 7.48633 19.886V19.2432L8.55776 18.1717V16.7432L9.98633 15.3146V14.2432L11.4149 15.6717H13.2006L13.9149 14.9574H16.772L18.1469 16.3323Z"
                            fill="#0B0B0B"
                          />
                          <path
                            d="M13.9155 6.5966V7.51089L12.512 8.88588H9.98693L8.07265 10.7645L9.98693 12.6395V14.4466L8.7155 13.1716H7.0798L4.62974 10.7108V7.88588L3.12974 6.38588H1.70117C2.49939 4.77605 3.73242 3.42245 5.25911 2.47525C6.7859 1.52883 8.54757 1.02794 10.3439 1.02883C11.1957 1.02704 12.0439 1.13954 12.8653 1.36456L11.7724 2.4574H9.62958V3.88597L11.0582 5.31454H12.6046L13.9155 6.5966Z"
                            fill="#0B0B0B"
                          />
                          <path
                            d="M10.3438 20.6716C7.69186 20.6716 5.14832 19.6181 3.27221 17.7432C1.39721 15.8673 0.34375 13.3235 0.34375 10.6716C0.34375 8.01975 1.39733 5.4762 3.27221 3.60009C5.14809 1.72509 7.69186 0.671631 10.3438 0.671631C12.9956 0.671631 15.5392 1.72521 17.4153 3.60009C19.2903 5.47597 20.3438 8.01975 20.3438 10.6716C20.3438 13.3235 19.2902 15.8671 17.4153 17.7432C15.5394 19.6182 12.9956 20.6716 10.3438 20.6716ZM10.3438 1.38592C7.88135 1.38592 5.51884 2.36448 3.77758 4.10546C2.0365 5.84653 1.05804 8.209 1.05804 10.6716C1.05804 13.1343 2.0366 15.4965 3.77758 17.2378C5.51865 18.9789 7.88112 19.9573 10.3438 19.9573C12.8064 19.9573 15.1687 18.9788 16.9099 17.2378C18.651 15.4967 19.6295 13.1343 19.6295 10.6716C19.6295 8.209 18.6509 5.84672 16.9099 4.10546C15.1688 2.36439 12.8064 1.38592 10.3438 1.38592Z"
                            fill="#0B0B0B"
                          />
                        </svg>
                      </span>
                      <h5 className="text-base text-black leading-[18px] -tracking-05 font-semibold capitalize">
                        Public sale launch
                      </h5>
                    </div>
                    <div className="bg-orange rounded-full py-[2px] px-[14px] text-[10px] font-semibold -tracking-08 text-black w-fit">
                      Coming Soon
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 max-w-full laptop:max-w-[40%] pb-4 md:pb-9 hidden laptop:block">
              <div className="w-7 h-auto *:w-full *:h-full ml-auto">
                {INFECTIVlOGOS.blackSingleLogo}
              </div>
            </div>
          </div>

          <div className=" mt-6 laptop:mt-[50px]">
            <p className="text-[30px] md:text-[45px] laptop:text-[80px] xl:text-[106px] font-normal leading-none laptop:leading-[80px] xl:leading-[125px] tracking-tighter xxl:-tracking-[8px]">
              Join <span className="inline-block font-bold">Inflectiv AI</span> to
              build the fastest, most secure,{" "}
              <span className="inline-block font-bold">decentralised</span>{" "}
              processing and{" "}
              <span className="inline-block font-bold">data network</span> for AI.
            </p>
          </div>

          <div className="mt-10 laptop:mt-20">
            <div className="grid md:grid-cols-2 laptop:grid-cols-4 gap-[10px] md:gap-4">
              <div className="col-span-1">
                <div className="nodeCard relative bg-[#cdc9d2]/90 rounded-[30px] border-[1px] border-gray/20 min-h-[400px] laptop:min-h-[534px] h-full md:pt-[37px] p-5 xl:pl-[40px] xl:pr-[30px] xl:pb-[30px] overflow-hidden">
                  <div className="absolute top-0 left-0 w-full h-full z-[2]">
                    {/* <video
                      loading="lazy"
                      poster={`${process.env.REACT_APP_S3_ASSETS_URL}lightnode-poster.webp`}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className="w-full h-full object-cover"
                    >
                      <source
                        src={`${process.env.REACT_APP_S3_ASSETS_URL}lightNode-vd.${isMobile ? "mp4" : "webm"}`}
                        type="video/mp4"
                      />
                    </video> */}

                    <SplineViewer url="https://prod.spline.design/Kl-scCbCuySOMVcV/scene.splinecode" />
                  </div>

                  <div className="relative flex flex-col justify-between h-full gap-5 z-10">
                    <h3 className="text-[24px] md:text-[32px] font-normal leading-9 text-black">
                      Light
                      <span className="block text-[30px] md:text-[38px] leading-7 md:leading-10 font-extrabold">
                        Node.
                      </span>
                    </h3>

                    <div className="relative flex flex-col gap-2">
                      <div className="flex flex-col">
                        <div className="text-[36px] md:text-[48px] leading-none text-black font-extrabold">
                          <span className="font-sans">60,000</span>
                          <span className="text-sm font-medium text-black/85 ml-1 md:ml-2">
                            Nodes
                          </span>
                        </div>
                      </div>

                      <div className="flex items-end gap-5">
                        <p className="text-sm leading-[22px] text-black/85 font-medium w-full">
                          {/* Operate on mobile devices
                          <br /> */}
                          Handle up to 20 AI instances<br /><br />
                        </p>
                        {/* <Button className="relative px-4 py-3 md:px-6 md:py-[17px] bg-black text-white !w-fit" onClick={() => setShowWhiteListModal(true)}>
                          Sign up for Whitelist
                          <span className={`ml-2 w-4 h-4 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}>
                            {ARROW_ICONS.circleArrow}
                          </span>
                        </Button> */}

                        <button className="w-[30px] h-[30px] flex items-center justify-center cursor-pointer hover:opacity-75 *:w-full *:h-full" onClick={() => setShowWhiteListModal(true)}>
                          {ARROW_ICONS.circleArrow}
                        </button>
                        {/* <SignUpButton customStyle={'relative px-4 py-3 md:px-6 md:py-[17px] bg-black text-white'} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="nodeCard relative bg-[#cdc9d2]/90 rounded-[30px] border-[1px] border-gray/20 min-h-[400px] laptop:min-h-[534px] h-full md:pt-[37px] p-5 xl:pl-[40px] xl:pr-[30px] xl:pb-[30px] overflow-hidden">
                  <div className="absolute top-0 left-0 w-full h-full z-[2]">
                    {/* <video
                      loading="lazy"
                      poster={`${process.env.REACT_APP_S3_ASSETS_URL}heavynode-poster.webp`}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className="w-full h-full object-cover"
                    >
                      <source
                        src={`${process.env.REACT_APP_S3_ASSETS_URL}heavyNode-vd.${isMobile ? "mp4" : "webm"}`}
                        type="video/mp4"
                      />
                    </video> */}

                    <SplineViewer url="https://prod.spline.design/QgYdNGBn1i48AsPB/scene.splinecode" />
                  </div>

                  <div className="relative flex flex-col justify-between h-full gap-5 z-10">
                    <h3 className="text-[24px] md:text-[32px] font-normal leading-9 text-black">
                      Heavy
                      <span className="block text-[30px] md:text-[38px] leading-7 md:leading-10 font-extrabold">
                        Node.
                      </span>
                    </h3>

                    <div className="relative flex flex-col gap-2">
                      <div className="flex flex-col">
                        <div className="text-[36px] md:text-[48px] leading-none text-black font-extrabold">
                          <span className="font-sans">30,000</span>
                          <span className="text-sm font-medium text-black/85 ml-1 md:ml-2">
                            Nodes
                          </span>
                        </div>
                      </div>

                      <div className="flex items-end gap-5">
                        <p className="text-sm leading-[22px] text-black/85 font-medium w-full">
                          {/* Run on PCs/Servers
                          <br /> */}
                          Handle up to 50 AI instances
                          <br />
                          Communicate with external servers
                        </p>
                        {/* <Button className="relative px-4 py-3 md:px-6 md:py-[17px] bg-black text-white !w-fit" onClick={() => setShowWhiteListModal(true)}>
                          Sign up for Whitelist
                          <span className={`ml-2 w-4 h-4 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}>
                            {ARROW_ICONS.circleArrow}
                          </span>
                        </Button> */}
                        <button className="w-[30px] h-[30px] flex items-center justify-center cursor-pointer hover:opacity-75 *:w-full *:h-full" onClick={() => setShowWhiteListModal(true)}>
                          {ARROW_ICONS.circleArrow}
                        </button>
                        {/* <SignUpButton customStyle={'relative px-4 py-3 md:px-6 md:py-[17px] bg-black text-white'} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 md:col-span-2">
                <div className="nodeCard relative bg-[#cdc9d2]/90 rounded-[30px] border-[1px] border-gray/20 min-h-[450px] laptop:min-h-[534px] h-full md:pt-[37px] p-5 xl:pl-[40px] xl:pr-[30px] xl:pb-[30px] overflow-hidden">
                  <div className="absolute top-0 right-0 w-full h-full z-[2]">
                    {/* <video
                      loading="lazy"
                      poster={`${process.env.REACT_APP_S3_ASSETS_URL}datanode-poster.webp`}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className="w-full h-full object-cover"
                    >
                      <source
                        src={`${process.env.REACT_APP_S3_ASSETS_URL}dataNode-vd.${isMobile ? "mp4" : "webm"}`}
                        type="video/mp4"
                      />
                    </video> */}

                    <SplineViewer url="spline/dataNode.splinecode" />
                  </div>

                  <div className="relative flex flex-col justify-between h-full gap-5 z-10">
                    <h3 className="text-[24px] md:text-[32px] font-normal leading-9 text-black">
                      Data
                      <span className="block text-[30px] md:text-[38px] leading-7 md:leading-10 font-extrabold">
                        Node.
                      </span>
                    </h3>

                    <div className="relative flex flex-col gap-0 laptop:gap-4">
                      <div className="flex flex-col">
                        <div className="text-[36px] md:text-[56px] leading-none text-black font-extrabold">
                          <span className="font-sans">10,000</span>
                          <span className="text-sm md:text-base font-medium text-black/85 ml-1 md:ml-2">
                            Nodes
                          </span>
                        </div>
                      </div>

                      <div className="flex justify-between items-start md:items-center flex-col md:flex-row gap-5 md:gap-2">
                        <p className="text-base xl:text-[24px] leading-[22px] md:leading-[28px] text-black/85 font-medium w-full md:w-[50%] xl:w-[60%]">
                          Decentralized database to store training data and secure
                          keys.
                        </p>
                        <Button className="relative px-4 py-3 md:px-6 md:py-[17px] bg-black text-white" onClick={() => setShowWhiteListModal(true)}>
                          Sign up for Whitelist
                          <span className={`ml-2 w-4 h-4 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}>
                            {ARROW_ICONS.circleArrow}
                          </span>
                        </Button>
                        {/* <SignUpButton customStyle={'relative px-4 py-3 md:px-6 md:py-[17px] bg-black text-white'} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {isShowWhiteListModal && (
        <SignupModal
          isToggled={isShowWhiteListModal}
          setToggled={() => setShowWhiteListModal(!isShowWhiteListModal)}
        />
      )}
    </>
  );
}

export default VerifierNode;
