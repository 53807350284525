import React, { useEffect, useState } from "react";
import { Button, Container, SignupModal } from "../index";
import {
  ARROW_ICONS,
  INFECTIVlOGOS,
  MenuIcon,
  SOCIAL_ICONS_HEADER,
} from "../../utils/constants";
import { motion } from "framer-motion";
import SignUpButton from "components/SignUpButton";
import { Link } from "react-router-dom";

const variants = {
  open: {
    opacity: 1,
    x: 0,
  },
  closed: {
    opacity: 0,
    x: "-100%",
  },
};

function Header() {
  const [viewSideBar, setViewSideBar] = useState(false);
  const [scroll, setScroll] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (viewSideBar) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <>
      <nav
        className={`fixed top-0 w-full z-[99] ease-linear py-5 lg:py-[26px] duration-300 ${scroll &&
          "!bg-white/50 !backdrop-blur-[50px] !py-[18px] !lg:py-[24px]"
          }`}
      >
        <Container className="!px-3">
          <div className="navbar-nav flex items-center justify-between gap-2 md:gap-4">
            <div className="flex items-center">
              {/* <div
                className={`w-9 h-9 laptop:w-[48px] laptop:h-[48px] flex items-center justify-center rounded-full border-[1.5px] cursor-pointer *:w-full *:h-full border-dim/15 text-white p-2 laptop:p-3 ${
                  scroll && "!text-black !border-black/20"
                }`}
                onClick={() => setViewSideBar(!viewSideBar)}
              >
                {MenuIcon.menu}
              </div> */}

              <Link
                className={`relative flex items-center w-[150px] xl:w-[197px] space-x-3 rtl:space-x-reverse 
                   z-20 *:w-full *:h-full ${scroll && "!border-black/20"
                  }`}
                to="/"
                onClick={() => scrollToTop()}
              >
                {scroll ? INFECTIVlOGOS.headerScrollLogo : INFECTIVlOGOS.header}
              </Link>
            </div>
            <div className="relative flex items-center gap-3">
              <ul className="hidden md:flex gap-3">
                {/* <li className="flex items-center justify-center">
                  <a
                    href=""
                    className={`w-10 h-10 laptop:w-[48px] laptop:h-[48px] flex items-center justify-center rounded-full border-[1.5px] border-dim/15 text-white hover:bg-black hover:text-white p-[10px] duration-300 ${
                      scroll && "!text-black !border-black/20 hover:!text-white"
                    }`}
                  >
                    {SOCIAL_ICONS_HEADER.discord}
                  </a>
                </li> */}
                <li className="flex items-center justify-center">
                  <a
                    href="https://twitter.com/inflectivAI"
                    className={`w-10 h-10 laptop:w-[48px] laptop:h-[48px] flex items-center justify-center rounded-full border-[1.5px] border-dim/15 text-white hover:bg-black hover:text-white p-[10px] duration-300 ${scroll && "!text-black !border-black/20 hover:!text-white"
                      }`}
                  >
                    {SOCIAL_ICONS_HEADER.twitter}
                  </a>
                </li>
              </ul>
              <SignUpButton
                customStyle={`light-button py-2 px-3 lg:py-[13px] lg:px-6 !text-xs md:!text-sm ${scroll && "!bg-black !text-white"
                  }`}
              />
            </div>
          </div>
        </Container>
      </nav>

      <motion.div
        animate={viewSideBar ? "open" : "closed"}
        variants={variants}
        transition={{ duration: 0.5 }}
        className={`sidebar fixed top-0 left-0 h-screen w-full laptop:w-[60vw] xl:w-[50vw] bg-white laptop:rounded-r-[40px] text-black border-2 border-gray/15 z-[999] ${!viewSideBar && "hidden"
          }`}
      >
        <div className="relative p-4 md:py-5 md:px-8 w-full h-full">
          <div
            className="absolute top-5 left-5 laptop:top-[44px] laptop:left-[70px] w-7 h-7 rounded-full bg-lightGray/[.08] flex items-center justify-center p-1 cursor-pointer z-10"
            onClick={() => setViewSideBar(false)}
          >
            <svg
              width="29"
              height="28"
              viewBox="0 0 29 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75195 5.83325L23.0842 22.1655"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.7498 22.1655L23.082 5.83325"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div className="relative w-full h-full py-[100px] z-[5]">
            <div className="relative w-full h-full flex flex-col gap-6">
              <ul className="flex flex-col justify-start items-start space-y-5 lg:space-y-10 overflow-x-auto">
                <li className="nav-link text-[32px] lg:text-[36px] laptop:text-[56px] font-bold px-[16px] xl:px-[35px] capitalize w-full">
                  <a
                    className="flex items-center justify-between gap-3 w-full"
                    href="/"
                  >
                    <div className="flex items-center">
                      <span className="text-base md:text-[24px] font-normal mr-4 lg:mr-10">
                        1.
                      </span>
                      Home
                    </div>
                    <span className="w-5 h-5 md:w-[36px] md:h-[36px] *:w-full *:h-full">
                      {ARROW_ICONS.arrowIcon}
                    </span>
                  </a>
                </li>
                <li className="nav-link text-[32px] lg:text-[36px] laptop:text-[56px] font-bold px-[16px] xl:px-[35px] capitalize w-full">
                  <a
                    className="flex items-center justify-between gap-3 w-full"
                    href="/"
                  >
                    <div className="flex items-center">
                      <span className="text-base md:text-[24px] font-normal mr-4 lg:mr-10">
                        2.
                      </span>
                      About
                    </div>
                    <span className="w-5 h-5 md:w-[36px] md:h-[36px] *:w-full *:h-full">
                      {ARROW_ICONS.arrowIcon}
                    </span>
                  </a>
                </li>
                <li className="nav-link text-[32px] lg:text-[36px] laptop:text-[56px] font-bold px-[16px] xl:px-[35px] capitalize w-full">
                  <a
                    className="flex items-center justify-between gap-3 w-full"
                    href="/"
                  >
                    <div className="flex items-center">
                      <span className="text-base md:text-[24px] font-normal mr-4 lg:mr-10">
                        3.
                      </span>
                      Why Inflectiv
                    </div>
                    <span className="w-5 h-5 md:w-[36px] md:h-[36px] *:w-full *:h-full">
                      {ARROW_ICONS.arrowIcon}
                    </span>
                  </a>
                </li>
                <li className="nav-link text-[32px] lg:text-[36px] laptop:text-[56px] font-bold px-[16px] xl:px-[35px] capitalize w-full">
                  <a
                    className="flex items-center justify-between gap-3 w-full"
                    href="/"
                  >
                    <div className="flex items-center">
                      <span className="text-base md:text-[24px] font-normal mr-4 lg:mr-10">
                        4.
                      </span>
                      How It Works?
                    </div>
                    <span className="w-5 h-5 md:w-[36px] md:h-[36px] *:w-full *:h-full">
                      {ARROW_ICONS.arrowIcon}
                    </span>
                  </a>
                </li>
                <li className="nav-link text-[32px] lg:text-[36px] laptop:text-[56px] font-bold px-[16px] xl:px-[35px] capitalize w-full">
                  <a
                    className="flex items-center justify-between gap-3 w-full"
                    href="/"
                  >
                    <div className="flex items-center">
                      <span className="text-base md:text-[24px] font-normal mr-4 lg:mr-10">
                        4.
                      </span>
                      Docs
                      <span className="w-5 h-5 md:w-[36px] md:h-[36px] flex items-center justify-center *:w-full *:h-full ml-4">
                        {ARROW_ICONS.externalLink}
                      </span>
                    </div>
                    <span className="w-5 h-5 md:w-[36px] md:h-[36px] *:w-full *:h-full">
                      {ARROW_ICONS.arrowIcon}
                    </span>
                  </a>
                </li>
              </ul>

              <div className="w-full h-auto flex items-start md:items-center justify-between flex-col md:flex-row gap-5 mt-auto">
                <div className="flex items-center">
                  <div className="max-w-[60px] w-full *:w-full *:h-full">
                    {INFECTIVlOGOS.colorStrokeLogo}
                  </div>
                  <ul className="flex gap-3 ml-6 pl-6 border-l-2 border-black/20">
                    <li className="flex items-center justify-center">
                      <a
                        href=""
                        className="w-10 h-10 laptop:w-[48px] laptop:h-[48px] flex items-center justify-center rounded-full border-[1.5px] border-black/20 text-black hover:bg-black hover:text-white p-[10px] duration-300"
                      >
                        {SOCIAL_ICONS_HEADER.discord}
                      </a>
                    </li>
                    <li className="flex items-center justify-center">
                      <a
                        href=""
                        className="w-10 h-10 laptop:w-[48px] laptop:h-[48px] flex items-center justify-center rounded-full border-[1.5px] border-black/20 text-black hover:bg-black hover:text-white p-[10px] duration-300"
                      >
                        {SOCIAL_ICONS_HEADER.twitter}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="flex items-center gap-4 md:gap-[30px]">
                  <a
                    href="#"
                    className="flex items-center text-black hover:underline hover:text-black/75 duration-300"
                  >
                    Privacy Policy
                    <span className="w-[14px] h-[14px] flex items-center justify-center *:w-full *:h-full ml-2">
                      {ARROW_ICONS.externalLink}
                    </span>
                  </a>
                  <a
                    href="#"
                    className="flex items-center text-black hover:underline hover:text-black/75 duration-300"
                  >
                    Terms & Conditions
                    <span className="w-[14px] h-[14px] flex items-center justify-center *:w-full *:h-full ml-2">
                      {ARROW_ICONS.externalLink}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Header;
