import React, { useEffect, useRef } from 'react';

const SplineViewer = ({ url }) => {
  const viewerRef = useRef(null);

  useEffect(() => {
    if (viewerRef.current) {
      viewerRef.current.setAttribute('url', url);
    }
  }, [url]);

  return <spline-viewer ref={viewerRef} events-target="global"></spline-viewer>;
};

export default SplineViewer;
