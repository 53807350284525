import React from "react";
import Banner from "./sections/Banner";
import RevolutionizeAiData from "./sections/RevolutionizeAiData";
import VerifierNode from "./sections/VerifierNode";
import HowItWorks from "./sections/HowItWorks";
import JoinNow from "./sections/JoinNow";
import NetworkAndNodes from "./sections/NetworkAndNodes";
import "./style.css";

function Homepage() {
    return (
        <>
            <Banner />
            <VerifierNode />
            <RevolutionizeAiData />
            <NetworkAndNodes />
            <HowItWorks />
            <JoinNow />
        </>
    );
}

export default Homepage;
