import React, { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "components";
import axios from "axios";

function SignupModal({ isToggled, setToggled }) {
  const [viewMode, setViewMode] = useState("ADD");
  const [message, setMessage] = useState("");
  const refEmail = useRef();
  const refWalletAddress = useRef();
  const [isSubmiting, setIsSubmiting] = useState(false);

  const doWhitelist = (e) => {
    e.preventDefault();
    console.log(refEmail.current.value);
    if (isSubmiting) {
      return;
    }
    setMessage("");

    if (refEmail.current.value.trim() === "") {
      setMessage("Please enter email address.");
      return;
    }
    if (refWalletAddress.current.value.trim() === "") {
      setMessage("Please enter wallet address.");
      return;
    }

    setIsSubmiting(true);
    setTimeout(() => {
      setIsSubmiting(false);
    }, 4000);

    const url = process.env.REACT_APP_API_WHITLIST_ENDPONT,
      body = {
        wallet_address: refWalletAddress.current.value,
        email: refEmail.current.value,
        // is_receive_notifications: refNotifications.current.checked ?? false,
        project_identifier:
          process.env.REACT_APP_ENV == "dev" ||
            process.env.REACT_APP_ENV == "stag"
            ? "dev-inflectiv-ai"
            : "inflectiv-ai",
      };

    axios
      .post(url + "whitelist/create", body)
      .then((response) => {
        console.log(response?.response);
        setIsSubmiting(false);
        if (response?.data?.status) {
          setViewMode("SUCCESS");
        }
        refEmail.current.value = "";
        refWalletAddress.current.value = "";
        // refNotifications.current.checked = false;
      })
      .catch((error) => {
        setMessage(
          error?.response?.data?.message ??
          "Something broken please contact support!"
        );
        setIsSubmiting(false);
      });
  };
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed top-0 left-0 w-screen h-screen bg-black/90 z-[999] overflow-y-auto overflow-x-hidden"
      >
        <div className="flex items-center justify-center w-full h-full px-4 forMobile">
          <motion.div
            initial={{
              opacity: 0,
              scale: 0,
              transition: {
                duration: 0.3,
              },
            }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                duration: 0.3,
                mass: 0.8,
                type: "spring",
              },
            }}
            exit={{
              opacity: 0,
              scale: 0,
              transition: {
                duration: 0.3,
              },
            }}
            className="relative max-w-[538px] w-full bg-white rounded-[30px] p-5 lg:p-[30px] mx-auto my-5"
          >
            {viewMode === "SUCCESS" && (
              <>
                <div
                  className="absolute top-4 right-4 lg:top-[18px] lg:right-[18px] w-7 h-7 rounded-full bg-lightGray/[.08] flex items-center justify-center p-1 cursor-pointer z-10"
                  onClick={() => setToggled(false)}
                >
                  <svg
                    width="29"
                    height="28"
                    viewBox="0 0 29 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.75195 5.83325L23.0842 22.1655"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.7498 22.1655L23.082 5.83325"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className="relative success bg-[#00DF8033] flex items-center justify-center mx-auto w-[58px] h-[58px] *:w-full *:h-full my-[40px] rounded-full shadow-[0_0_0_11px_rgba(0,223,128,0.2)]">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="Subtract"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM15.1224 7.47229C15.5674 6.94444 15.5001 6.15584 14.9723 5.71091C14.4444 5.26598 13.6558 5.33319 13.2109 5.86105L7.84483 12.2272L5.95576 9.98605C5.51083 9.45819 4.72223 9.39098 4.19438 9.83591C3.66653 10.2808 3.59931 11.0694 4.04424 11.5973L6.88907 14.9723C7.12658 15.2541 7.47631 15.4167 7.84483 15.4167C8.21334 15.4167 8.56308 15.2541 8.80059 14.9723L15.1224 7.47229Z"
                      fill="#00DF80"
                    />
                  </svg>
                </div>

                <h2 className="text-center text-[26px] lg:text-[42px] leading-[30px] lg:leading-[42px] font-semibold text-black tracking-tighter lg:tracking-[-3px]">
                  Thank you!
                </h2>
                <p className="text-sm lg:text-base text-black font-medium leading-[26px] text-center mt-[10px]">
                  Your details has been successfully submitted.{" "}
                </p>
                <Button
                  className="bg-black text-white w-full px-4 py-4 md:py-[18px] mt-5 lg:mt-10"
                  onClick={() => setToggled(false)}
                >
                  Done
                </Button>
              </>
            )}
            {viewMode === "ADD" && (
              <>
                <h2 className="text-center text-[26px] lg:text-[42px] leading-[30px] lg:leading-[42px] font-semibold text-black tracking-tighter lg:tracking-[-3px]">
                  Sign up for Whitelist
                </h2>
                <form action="" className="mt-8 ">
                  <div
                    className="absolute top-4 right-4 lg:top-[18px] lg:right-[18px] w-7 h-7 rounded-full bg-lightGray/[.08] flex items-center justify-center p-1 cursor-pointer z-10"
                    onClick={() => setToggled(false)}
                  >
                    <svg
                      width="29"
                      height="28"
                      viewBox="0 0 29 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.75195 5.83325L23.0842 22.1655"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.7498 22.1655L23.082 5.83325"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="mb-4 ">
                    <input
                      type="email"
                      name="email"
                      ref={refEmail}
                      className="border-[1px] border-black/20 bg-[#F8F8F8] rounded-[100px] w-full font-sm font-medium leading-6 px-4 py-4 md:py-[18px] focus:outline-none"
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className="mb-2 relative">
                    <input
                      type="text"
                      name="text"
                      ref={refWalletAddress}
                      className="border-[1px] border-black/20 bg-[#F8F8F8] rounded-[100px] w-full font-sm font-medium leading-6 px-4 py-4 md:py-[18px] pr-[50px] md:pr-[60px] focus:outline-none"
                      placeholder="Enter your ETH address"
                    />

                    <span className="absolute top-2/4 -translate-y-2/4 right-2 md:right-4 bg-black/[.06] w-[38px] h-[38px] rounded-full flex items-center justify-center *:w-full *:h-full p-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="21"
                        viewBox="0 0 12 21"
                        fill="none"
                      >
                        <path
                          d="M5.98023 0L5.84961 0.443959L5.84961 13.3266L5.98023 13.457L11.9602 9.92225L5.98023 0Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M5.97999 -1.9762e-10L3.27899e-10 9.92225L5.97999 13.4571L5.97999 7.20419L5.97999 -1.9762e-10Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M5.9779 15.4027L5.9043 15.4925L5.9043 20.0815L5.9779 20.2965L11.9615 11.8698L5.9779 15.4027Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M5.97999 20.2966L5.97999 15.4027L0 11.8698L5.97999 20.2966Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M5.98047 13.457L11.9604 9.92228L5.98047 7.20422L5.98047 13.457Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M0 9.92228L5.97989 13.457L5.97989 7.20422L0 9.92228Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </div>

                  {/* <div className="modal-checkbox mb-5 md:mb-10">
                  <input
                    type="checkbox"
                    id="html"
                    ref={refNotifications}
                    className="absolute appearance-none"
                  />
                  <label
                    htmlFor="html"
                    className="relative flex text-black opacity-75"
                  >
                    <span className="inline-block text-xsm md:text-sm font-medium -tracking-08 leading-none text-black">
                      Yes, I'd like to receive occasional promotions and special
                      offers.
                    </span>
                  </label>
                </div> */}

                  {/* {message && ( */}
                  <div className="relative w-full">
                    <span className="absolute left-0 inline-block text-xsm md:text-sm font-medium -tracking-08 text-[red]">
                      {message ?? ""}
                    </span>
                  </div>

                  <Button
                    className="bg-black text-white w-full px-4 py-4 md:py-[18px] mt-6 md:mt-[30px]"
                    onClick={doWhitelist}
                  >
                    {isSubmiting ? "Submitting" : "Submit"}
                  </Button>
                </form>
              </>
            )}
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default SignupModal;
