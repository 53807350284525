import React from 'react'

function Container({ children, className }) {
  return (
    <div className={`max-w-container px-4 mx-auto ${className}`}>
      {children}
    </div>
  )
}

export default Container