import React, { useEffect, useRef } from "react";
import { Container, CustomLink } from "components";
import { useInView } from "framer-motion";
import { ARROW_ICONS } from "utils/constants";
import SignUpButton from "components/SignUpButton";
import { useMediaQuery } from "hooks/useMediaQuery";
import { joinNowPoster } from "assets";

function JoinNow() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const isMobile = useMediaQuery("(max-width: 768px)");

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
    }
  }, []);

  return (
    <section
      className="joinNowSection relative w-full py-[30px] md:py-16 laptop:py-[75px]"
      ref={ref}
    >
      <Container>
        <div className="rounded-[30px] laptop:rounded-[60px] bg-black overflow-hidden">
          <div className="grid laptop:grid-cols-2 gap-4">
            <div className="col-span-1 py-10 px-5 laptop:py-[113px] laptop:pl-[100px]">
              <div className="flex flex-col justify-center h-full">
                <div className="tag text-sm md:text-base lg:text-xl leading-6 font-medium tracking-03 p-3 md:py-3 md:px-4 rounded-full w-fit border-2 border-orange/10 backdrop-blur-sm mb-4 md:mb-5 text-orange flex items-center laptop:ml-0 mx-auto">
                  <span className="mr-[10px]">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3398 9.38587C15.7825 6.47153 16.4058 2.41167 16.3384 1.18192C16.3384 1.04716 16.271 0.929236 16.1868 0.828161C16.1026 0.743932 15.9846 0.676548 15.833 0.676548C14.6033 0.609164 10.5434 1.21562 7.64593 3.65827L6.9721 3.40558C5.79289 2.96759 4.46206 3.32135 3.65346 4.28157L1.78356 6.48838C1.51403 6.7916 1.6488 7.28014 2.03625 7.4149L4.04091 8.15612C3.60292 8.89734 3.29969 9.52064 3.09754 9.95863C2.92908 10.3124 3.01331 10.7335 3.29969 11.0199L5.99504 13.7321C6.26457 14.0016 6.68572 14.0859 7.05633 13.9174C7.49432 13.7153 8.11762 13.412 8.85884 12.974L9.58321 14.9787C9.71798 15.3662 10.2065 15.4841 10.5097 15.2314L12.6997 13.3615C13.6599 12.5529 14.0137 11.2221 13.5757 10.0429L13.3398 9.38587ZM12.0764 7.28014C11.4363 7.92028 10.3918 7.92028 9.73482 7.28014C9.09468 6.63999 9.09468 5.59555 9.73482 4.93856C10.375 4.29841 11.4194 4.29841 12.0764 4.93856C12.7165 5.59555 12.7165 6.63999 12.0764 7.28014Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M4.83315 14.4231L3.62024 15.6192C3.3844 15.855 3.3844 16.2593 3.62024 16.4952C3.85608 16.731 4.26039 16.731 4.49623 16.4952L5.70913 15.2823C5.94497 15.0464 5.94497 14.6421 5.70913 14.4063C5.45644 14.1704 5.06899 14.1704 4.83315 14.4231Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M4.14127 12.8732C3.90542 12.6373 3.50112 12.6373 3.26528 12.8732L1.34485 14.8104C1.10901 15.0463 1.10901 15.4506 1.34485 15.6864C1.58069 15.9223 1.98499 15.9223 2.22084 15.6864L4.14127 13.7323C4.39396 13.4965 4.39396 13.109 4.14127 12.8732Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M2.60952 12.1991C2.84536 11.9633 2.84536 11.559 2.60952 11.3231C2.37368 11.0873 1.96938 11.0873 1.73354 11.3231L0.520632 12.536C0.284789 12.7719 0.284789 13.1762 0.520632 13.412C0.756474 13.6479 1.16078 13.6479 1.39662 13.412L2.60952 12.1991Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                  Coming Soon
                </div>

                <h2 className="text-[36px] md:text-[60px] laptop:text-[96px] font-semibold leading-[43px] md:leading-[65px] laptop:leading-[105px] tracking-[-2.48px] laptop:tracking-[-7.40px] mb-4 md:mb-[22px] text-white text-center laptop:text-left">
                  First Adopter
                </h2>
                <p className="text-sm md:text-base laptop:text-[28px] font-normal leading-5 laptop:leading-8 text-white/85 -tracking-09 laptop:-tracking-16 text-center laptop:text-left">
                  Introducing the Future of AI Agents.
                </p>
                <div className="mt-[30px] laptop:mt-[40px]">
                  <div
                    className="flex items-center justify-center laptop:justify-start flex-wrap md:flex-nowrap gap-[26px] mt-5 md:mt-[58px] *:min-w-[210px] *:md:min-w-[227px]"
                    style={{
                      transform: isInView ? "none" : "translatey(50px)",
                      opacity: isInView ? 1 : 0,
                      transition:
                        "all .9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.20s",
                    }}
                  >
                    <SignUpButton
                      customStyle={
                        "gradient-effect light-button relative px-4 py-3 md:px-6 md:py-[17px]"
                      }
                    />

                    {/* <CustomLink className="gradient-effect bg-orange relative px-4 py-3 md:px-6 md:py-[17px] group">
                      Gitbook
                      <span className="ml-2 w-4 h-4 md:w-[26px] md:h-[26px] duration-300 flex items-center justify-center *:w-full *:h-full">
                        {ARROW_ICONS.circleArrow}
                      </span>
                    </CustomLink> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 px-5 py-10 pt-0 laptop:p-[50px]">
              <div className="relative flex items-center justify-center w-full h-full max-h-[300px] md:max-h-[500px] laptop:max-h-[757px] networkNode after:!z-0">
                <div className="relative videoContain w-full h-full before:bg-leftGlassEffect before:bg-no-repeat after:bg-rightGlassEffect after:bg-no-repeat">
                  <video
                    ref={videoRef}
                    loading="lazy"
                    poster={`${process.env.REACT_APP_S3_ASSETS_URL}joinNow-poster.webp`}
                    autoPlay
                    loop
                    playsInline
                    className="w-[139px] md:w-[230px] laptop:w-[350px] mx-auto h-full relative z-10 rounded-[16px] md:rounded-[28px] laptop:rounded-[45px]"
                  >
                    <source src={`${process.env.REACT_APP_S3_ASSETS_URL}joinNow-vd.${isMobile ? "mp4" : "webm"}`} type={`video/${isMobile ? "mp4" : "webm"}`} />
                  </video>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default JoinNow;
