import React, { useState } from "react";
import {
    Button,
    Container,
    SectionDetail,
    SectionTag,
    SignupModal,
} from "components";
import { ARROW_ICONS } from "utils/constants";
import { useMediaQuery } from "hooks/useMediaQuery";

function Banner() {
    const [isShowWhiteListModal, setShowWhiteListModal] = useState(false);
    const isMobile = useMediaQuery("(max-width: 768px)");

    const bodyElement = document.body;
    isShowWhiteListModal
        ? bodyElement.classList.add("overflow-hidden")
        : bodyElement.classList.remove("overflow-hidden");

    return (
        <>
            <section
                className="relative w-full min-h-screen h-full py-24 md:py-36 z-30 flex flex-col justify-center laptop:justify-start xl:justify-center overflow-hidden bg-black"
                id="hero-section"
            >
                <div className="fixedScreen absolute top-0 left-0 w-full min-h-screen h-full">
                    <video
                        loading="lazy"
                        poster={`${process.env.REACT_APP_S3_ASSETS_URL}inflectiv-bg.webp`}
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="w-full h-full object-cover"
                    >
                        <source
                            src={`${process.env.REACT_APP_S3_ASSETS_URL}inflective_bg_cs.mp4`}
                            type="video/mp4"
                        />
                    </video>
                </div>
                <Container className="w-full h-full z-[2]">
                    <div className="max-w-[928px] mx-auto h-full text-center">
                        <div className="relative xl:mb-24">
                            <SectionTag
                                title="Adopt Inflectiv, Enhance AI."
                                className="mb-4 md:mb-5 mx-auto text-white"
                            />
                            <h1 className="text-[36px] md:text-[60px] laptop:text-[96px] font-bold leading-[43px] md:leading-[65px] laptop:leading-[105px] tracking-[-2.48px] md:tracking-[-4.48px] mb-4 md:mb-[16px] text-white">
                                The Global Engine for Decentralized AI.
                            </h1>
                            <SectionDetail detail="Billions of users can now create infinite applications, in any sector, powered by Inflectiv." />

                            <div className="flex items-center justify-center flex-wrap md:flex-nowrap gap-5 md:gap-10 mt-5 lg:mt-[64px]">
                                {/* <SignUpButton
                                    customStyle={
                                        "gradient-effect light-button relative px-4 py-3 md:px-6 md:py-[17px]"
                                    }
                                /> */}
                                <Button
                                    className="gradient-effect light-button relative px-4 py-3 md:px-6 md:py-[17px]"
                                    onClick={() => setShowWhiteListModal(true)}
                                >
                                    Sign up for Whitelist
                                    <span
                                        className={`ml-2 w-4 h-4 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                                    >
                                        {ARROW_ICONS.circleArrow}
                                    </span>
                                </Button>
                                {/* <CustomLink className="text-orange text-base group">
                                    What Is A Verifier Node
                                    <span className="ml-2 w-4 h-4 md:w-[18px] md:h-[18px] flex items-center justify-center *:w-full *:h-full duration-300">
                                        {ARROW_ICONS.externalLink}
                                    </span>
                                </CustomLink> */}
                            </div>
                        </div>

                        {/* <div className="relative flex items-end justify-center gap-3 mt-6 md:mt-10 laptop:mt-[52px]">
                        <div className="text-white pl-2 border-l-2 border-orange bgGradient relative text-xs md:text-sm">Partnered with</div>
                        <img src={googleLogo} alt="" className="max-w-[138px] w-full" />
                    </div> */}
                    </div>
                </Container>

                {/* <div className="relative flex items-end justify-center mt-6 md:mt-10 laptop:mt-[52px]">
                <div className="relative max-h-[450px] laptop:max-h-[600px] h-full md:max-w-[350px] laptop:max-w-[390px] w-full flex items-center justify-center">
                    <img src={welcomeHeroSection} alt=""
                        className="relative -bottom-[150px]"
                    />
                </div>
            </div> */}

                {/* <div className="absolute w-full h-auto bottom-[5vh] left-0">
                    <LogoSlider />
                </div> */}
            </section>

            {isShowWhiteListModal && (
                <SignupModal
                    isToggled={isShowWhiteListModal}
                    setToggled={() => setShowWhiteListModal(!isShowWhiteListModal)}
                />
            )}
        </>
    );
}

export default Banner;
