import React, { useRef } from "react";
import { Container } from "components";
import { useInView } from "framer-motion";
import { INFECTIVlOGOS } from "utils/constants";

function HowItWorks() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section
      className="relative w-full py-[30px] md:py-16 laptop:py-[75px]"
      id="howItWorks"
      ref={ref}
    >
      <Container>
        <div className="grid laptop:grid-cols-5 gap-2 items-end relative borderBottom">
          <div className="col-span-1 pb-4 laptop:pb-9 md:pl-4 w-full hidden laptop:block">
            <div className="w-[16px] h-auto *:w-full *:h-full mr-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
              >
                <g opacity="0.74">
                  <path
                    d="M0.689161 13.8629C0.521797 13.8629 0.466009 13.7513 0.503201 13.6212L4.87326 1.01307C4.91045 0.901491 5.02203 0.845703 5.1336 0.845703H8.48088C8.59246 0.845703 8.70403 0.901491 8.74123 1.01307L13.1113 13.6212C13.1485 13.7513 13.0927 13.8629 12.9253 13.8629H10.3033C10.1917 13.8629 10.0987 13.8071 10.0615 13.6769L9.35489 11.371H4.2038L3.49716 13.6769C3.45996 13.7885 3.36698 13.8629 3.25541 13.8629H0.689161ZM4.92905 9.06513H8.62965L6.82584 3.24459H6.73286L4.92905 9.06513Z"
                    fill="currentColor"
                  />
                  <path
                    d="M13.9331 13.8629C13.7844 13.8629 13.71 13.7885 13.71 13.6397V1.06886C13.71 0.920087 13.7844 0.845703 13.9331 0.845703H16.5924C16.7225 0.845703 16.7969 0.920087 16.7969 1.06886V13.6397C16.7969 13.7885 16.7225 13.8629 16.5924 13.8629H13.9331Z"
                    fill="currentColor"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div className="col-span-1 laptop:col-span-3 text-center">
            <h2 className="text-[40px] md:text-[45px] laptop:text-[80px] xl:text-[100px] font-normal leading-none laptop:leading-[80px] xl:leading-[110px] tracking-tighter laptop:-tracking-[6px] text-center w-fit bg-white relative z-[1] mx-auto px-2">
              <span
                className="relative block"
                style={{
                  transform: isInView ? "none" : "translatey(50px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all .9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
                }}
              >
                How to
              </span>
              <span
                className="relative block font-bold bg-white"
                style={{
                  transform: isInView ? "none" : "translatey(50px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all .9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
              >
                Purchase A Node
              </span>
            </h2>
          </div>
          <div className="col-span-1 pb-4 laptop:pb-9 md:pl-4 w-full hidden laptop:block">
            <div className="w-7 h-auto *:w-full *:h-full ml-auto">
              {INFECTIVlOGOS.blackSingleLogo}
            </div>
          </div>
        </div>

        <div className="relative mt-10 lg:mt-[70px]">
          <div className="grid gap-[10px] overflow-x-auto auto-cols-[85vw] md:auto-cols-[592px] grid-flow-col laptop:grid-cols-3 pb-4 scrollTransparent duration-300">
            <div className="col-span-1">
              <div className="bg-black px-4 py-5 md:px-[30px] md:py-[22px] rounded-[30px] min-h-[350px] md:min-h-[400px] laptop:min-h-[457px] flex flex-col justify-between bg-node1 bg-no-repeat bg-cover">
                <div className="text-white self-end text-[50px] lg:text-[74px] -tracking-[4px] font-medium">
                  01.
                </div>

                <h3 className="text-[20px] lg:text-[30px] lg:leading-[36px] font-normal text-white">
                  Purchase
                  <span className="block text-[32px] lg:text-[48px] font-semibold leading-10 lg:leading-[57px] -tracking-[2px]">
                    Your Node
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-span-1">
              <div className="bg-black px-4 py-5 md:px-[30px] md:py-[22px] rounded-[30px] min-h-[350px] md:min-h-[400px] laptop:min-h-[457px] flex flex-col justify-between bg-node2 bg-no-repeat bg-cover">
                <div className="text-white self-end text-[50px] lg:text-[74px] -tracking-[4px] font-medium">
                  02.
                </div>

                <h3 className="text-[20px] lg:text-[30px] lg:leading-[36px] font-normal text-white">
                  Run
                  <span className="block text-[32px] lg:text-[48px] font-semibold leading-10 lg:leading-[57px] -tracking-[2px]">
                    The Node
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-span-1">
              <div className="bg-black px-4 py-5 md:px-[30px] md:py-[22px] rounded-[30px] min-h-[350px] md:min-h-[400px] laptop:min-h-[457px] flex flex-col justify-between bg-node3 bg-no-repeat bg-cover">
                <div className="text-white self-end text-[50px] lg:text-[74px] -tracking-[4px] font-medium">
                  03.
                </div>

                <h3 className="text-[20px] lg:text-[30px] lg:leading-[36px] font-normal text-white">
                  Earn Token Emissions
                  <span className="block text-[32px] lg:text-[48px] font-semibold leading-10 lg:leading-[57px] -tracking-[2px]">
                    And Monthly Revenues
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default HowItWorks;
