import React, { useRef } from "react";
import { Container } from "components";
import { ARROW_ICONS, INFECTIVlOGOS, SOCIAL_ICONS } from "utils/constants";
import { motion } from "framer-motion";

function Footer() {
    const ref = useRef(null);
    return (
        <footer className="relative z-10 pt-[25px] bg-white overflow-hidden" ref={ref}>
            <Container className="h-full">
                <div className="grid md:grid-cols-3 gap-4 md:gap-10 h-full pb-4">
                    <div className="col-span-1 md:col-span-2 z-10 h-full">
                        <div className="flex items-center justify-center md:justify-start">
                            <div className="relative max-w-[45px] lg:max-w-[52px] w-full h-auto *:w-full *:h-full">
                                <img src={`${process.env.REACT_APP_S3_ASSETS_URL}infectivColorLogo.webp`} alt="" />
                            </div>

                            <div className="text-black border-l text-xs md:text-sm border-black pl-2 ml-2 lg:pl-4 lg:ml-4 font-medium leading-none md:leading-[20px] capitalize">
                                The global engine for Decentralized AI.
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <ul className="flex gap-[10px] socials justify-center md:justify-end text-blue">
                            {SOCIAL_ICONS.map((icon) => (
                                <li className="flex items-center justify-center" key={icon.name}>
                                    <a
                                        href={icon.url}
                                        className="w-[44px] h-[44px] border-[1.5px] border-black/[.17] rounded-full flex items-center justify-center cursor-pointer text-black hover:text-black/70 duration-300 hover:bg-black hover:text-white"
                                        target="_blank"
                                    >
                                        {icon.svg}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="laptop:py-[23px] relative text-black text-xsm xl:text-sm font-normal">
                    <div className="grid md:grid-cols-4 laptop:grid-cols-4 xl:grid-cols-5 items-end gap-4">
                        <div className="col-span-1 md:col-span-2 laptop:col-span-1 text-center md:text-left">
                            <p>Copyright © {new Date().getUTCFullYear()} Inflectiv</p>
                        </div>
                        <div className="col-span-1 md:col-span-4 laptop:col-span-2 xl:col-span-3 order-3 laptop:order-none">
                            <motion.div
                                className="relative w-full min-h-[90px] md:min-h-[200px] laptop:min-h-[229px] *:w-full *:h-full *:absolute *:-bottom-[23px] *:md:-bottom-[55px] *:md:laptop:-bottom-[78px]"
                            >
                                {INFECTIVlOGOS.footerLogo}
                            </motion.div>
                        </div>
                        <div className="col-span-1 md:col-span-2 laptop:col-span-1">
                            {/* <div className="flex items-center justify-center md:justify-end gap-4 xl:gap-6">
                                <a
                                    href="/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-2 hover:text-black hover:underline"
                                >
                                    Privacy Policy
                                    <span className="w-[14px] h-[14px] flex items-center justify-center *:w-full *:h-full">
                                        {ARROW_ICONS.externalLink}
                                    </span>
                                </a>
                                <a
                                    href="/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-2 hover:text-black hover:underline"
                                >
                                    Terms & Conditions
                                    <span className="w-[14px] h-[14px] flex items-center justify-center *:w-full *:h-full">
                                        {ARROW_ICONS.externalLink}
                                    </span>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;
