import React from "react";

function SectionTag({ title, className = "", props }) {
    return (
        <div
            className={`tag text-xs md:text-xl leading-6 font-medium tracking-03 p-3 md:py-3 md:px-4 rounded-full w-fit border-2 border-orange/10 backdrop-blur-sm ${className}`}
            {...props}
        >
            {title}
        </div>
    );
}
function SectionTitle({ title, className = "", props }) {
    return (
        <h2
            className={`text-[30px] md:text-[45px] laptop:text-[76px] font-semibold leading-none laptop:leading-[76px] tracking-tighter laptop:-tracking-[6px] ${className}`}
            {...props}
        >
            {title}
        </h2>
    );
}

function SectionDetail({ detail, className = "", props }) {
    return (
        <p
            className={`text-sm md:text-base laptop:text-[20px] font-medium leading-5 md:leading-7 text-white -tracking-04 ${className}`}
            {...props}
        >
            {detail}
        </p>
    );
}

export { SectionTitle, SectionDetail, SectionTag };
