import React, { useRef } from "react";
import { Container } from "components";
import { INFECTIVlOGOS } from "utils/constants";
import { useInView } from "framer-motion";

function RevolutionizeAiData() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section
      className="relative w-full py-[30px] md:py-16 laptop:py-[75px]"
      id="featureAi"
      ref={ref}
    >
      <Container>
        <div className="flex flex-col md:flex-row md:items-end relative borderBottom">
          <div className="flex-1 max-w-full laptop:max-w-[50%] xl:max-w-[55%] ">
            <h2 className="text-[40px] md:text-[45px] laptop:text-[74px] xl:text-[100px] font-normal leading-none laptop:leading-[80px] xl:leading-[110px] tracking-tighter laptop:-tracking-[6px]">
              <span
                style={{
                  transform: isInView ? "none" : "translatey(50px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all .9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
                }}
                className="relative block"
              >
                Inflectiv Runs the
              </span>
              <span
                className="relative block font-bold"
                style={{
                  transform: isInView ? "none" : "translatey(50px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all .9s cubic-bezier(0.17, 0.55, 0.55, 1) .5s",
                }}
              >
                AI Apps of the Future
              </span>
            </h2>
          </div>

          <div className="max-w-full md:max-w-[40%] laptop:max-w-[50%] xl:max-w-[45%] flex-1 pb-4 md:pb-9 md:pl-4 hidden laptop:block">
            <div className="w-7 h-auto *:w-full *:h-full ml-auto">
              {INFECTIVlOGOS.blackSingleLogo}
            </div>
          </div>
        </div>

        <div className="laptop:max-w-[661px] ml-auto mt-6 laptop:mt-[50px]">
          <p className="text-sm md:text-base laptop:text-[28px] font-normal leading-5 laptop:leading-8 text-black/85 -tracking-09 laptop:-tracking-16">
            Allowing all AI apps to be run on a decentralised, secure network, powered by the users, with multiple <span className="font-semibold">AAA AI</span> applications launching within the first year.
          </p>
        </div>

        <div className="relative mt-10 lg:mt-[70px]">
          <div className="grid overflow-x-auto gap-[10px] auto-cols-[85vw] md:auto-cols-[356px] grid-flow-col laptop:grid-cols-5 pb-4 scrollTransparent duration-300">
            <div className="col-span-1">
              <div className="bg-black px-[30px] py-[22px] rounded-[30px] min-h-[400px] laptop:min-h-[490px] flex items-end bg-feature1 bg-no-repeat bg-cover bg-center w-full h-full">
                <h3 className="text-[24px] leading-6 font-normal text-white">
                  Low
                  <span className=" block text-[38px] font-semibold leading-[41px]">
                    Cost
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-span-1">
              <div className="bg-black px-[30px] py-[22px] rounded-[30px] min-h-[400px] laptop:min-h-[490px] flex items-end bg-feature2 bg-no-repeat bg-cover bg-center w-full h-full">
                <h3 className="text-[24px] leading-6 font-normal text-white">
                  Maximum
                  <span className=" block text-[38px] font-semibold leading-[41px]">
                    Efficiency
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-span-1">
              <div className="bg-black px-[30px] py-[22px] rounded-[30px] min-h-[400px] laptop:min-h-[490px] flex items-end bg-feature3 bg-no-repeat bg-cover bg-center w-full h-full">
                <h3 className="text-[24px] leading-6 font-normal text-white">
                  Carbon
                  <span className=" block text-[38px] font-semibold leading-[41px]">
                    Neutral
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-span-1">
              <div className="bg-black px-[30px] py-[22px] rounded-[30px] min-h-[400px] laptop:min-h-[490px] flex items-end bg-feature4 bg-no-repeat bg-cover bg-center w-full h-full">
                <h3 className="text-[24px] leading-6 font-normal text-white">
                  Perpetual
                  <span className=" block text-[38px] font-semibold leading-[41px]">
                    Machine <br /> Learning{" "}
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-span-1">
              <div className="bg-black px-[30px] py-[22px] rounded-[30px] min-h-[400px] laptop:min-h-[490px] flex items-end bg-feature5 bg-no-repeat bg-cover bg-center w-full h-full">
                <h3 className="text-[24px] leading-6 font-normal text-white">
                  Secure
                  <span className=" block text-[38px] font-semibold leading-[41px]">
                    Multiparty <br /> Computation
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default RevolutionizeAiData;
