import React from "react";

export default function Button({
  children,
  type = "button",
  className = "",
  ...props
}) {
  return (
    <button
      type={type}
      className={`inline-flex justify-center items-center bg-transparent text-xsm lg:text-sm/7 font-medium rounded-full outline-0 -tracking-[0.42] duration-300 cursor-pointer ${className} hover:!opacity-75`}
      {...props}
    >
      {children}
    </button>
  );
}