import { Navigate, Route, Routes } from "react-router-dom";
import Homepage from "./home";
import { Layout } from "components";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Homepage />
            </Layout>
          }
        />
        {/* <Route
          path="/terms-and-conditions"
          element={
            <Layout>
              <TermsAndConditions />
            </Layout>
          }
        /> */}
        <Route path="/*" element={<Navigate to={"/"} />} />
      </Routes>
    </>
  );
}

export default App;
