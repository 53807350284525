import React, { useRef } from "react";
import { Container, SplineViewer } from "components";
import { useInView } from "framer-motion";

function NetworkAndNodes() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <>
      <section
        className="relative w-full py-[30px] md:py-16 laptop:py-[75px]"
        ref={ref}
      >
        <Container>
          <div className="bg-black px-5 py-8 md:p-10 laptop:px-[60px] laptop:pt-[70px] laptop:pb-[0px] rounded-[30px] laptop:rounded-[60px] overflow-hidden">
            <h2 className="text-[40px] md:text-[45px] laptop:text-[80px] xl:text-[100px] font-normal leading-none laptop:leading-[80px] xl:leading-[110px] tracking-tighter laptop:-tracking-[6px] text-white text-center">
              <span
                className="relative block"
                style={{
                  transform: isInView ? "none" : "translatey(50px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all .9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
                }}
              >
                How
              </span>
              <span
                className="relative block font-bold"
                style={{
                  transform: isInView ? "none" : "translatey(50px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all .9s cubic-bezier(0.17, 0.55, 0.55, 1) .5s",
                }}
              >
                The Network Works
              </span>
            </h2>

            <div className="relative mt-[50px] laptop:mt-10 flex flex-col">
              <div className="z-10 grid gap-[15px] md:order-1 overflow-x-auto overflow-y-hidden md:overflow-x-hidden auto-cols-[75vw] md:grid-cols-2 grid-flow-col md:grid-flow-row laptop:grid-cols-4 pb-4 scrollTransparent duration-300">
                <div className="col-span-1">
                  <div
                    className="network-work-info-box bg-[#D9D9D90A] flex items-center px-4 py-6 lg:py-5 lg:px-5 border-2 border-white/10 rounded-xl h-full laptop:min-h-[175px] text-center bg-noise bg-no-repeat bg-cover bg-center"
                    style={{
                      opacity: isInView ? 1 : 0,
                      transition: "all .9s linear 1s",
                    }}
                  >
                    <p className="text-sm font-normal leading-[24px] text-white/75 capitalize">
                      Inflectiv operates a <span className="text-white text-medium">scalable node network</span> enabling Web 3 AI applications to connect with both large commercial and decentralized AI systems, supporting any model.
                    </p>
                  </div>
                </div>

                <div className="col-span-1">
                  <div
                    className="bg-[#D9D9D90A] network-work-info-box  flex items-center px-4 py-6 lg:py-5 lg:px-5 border-2 border-white/10 rounded-xl h-full laptop:min-h-[175px] text-center bg-noise bg-no-repeat bg-cover bg-center"
                    style={{
                      opacity: isInView ? 1 : 0,
                      transition: "all .5s linear 1.15s",
                    }}
                  >
                    <p className="text-sm font-normal leading-[24px] text-white/75 capitalize">
                      <span className="text-white text-medium">
                        Your valuable training data
                      </span>{" "}
                      powers AI tools and services, remaining secure and decentralized on the Inflectiv network, stored on-chain or across its decentralized data network.

                    </p>
                  </div>
                </div>

                <div className="col-span-1">
                  <div
                    className="bg-[#D9D9D90A] network-work-info-box  flex items-center px-4 py-6 lg:py-5 lg:px-5 border-2 border-white/10 rounded-xl h-full laptop:min-h-[175px] text-center bg-noise bg-no-repeat bg-cover bg-center"
                    style={{
                      opacity: isInView ? 1 : 0,
                      transition: "all .5s linear 1.30s",
                    }}
                  >
                    <p className="text-sm font-normal leading-[24px] text-white/75 capitalize">
                      Node operators, token holders, and application builders {" "}
                      <span className="text-white text-medium">
                        earn revenue
                      </span>{" "}
                      by participating.

                    </p>
                  </div>
                </div>

                <div className="col-span-1">
                  <div
                    className="bg-[#D9D9D90A] network-work-info-box  flex items-center px-4 py-6 lg:py-5 lg:px-5 border-2 border-white/10 rounded-xl h-full laptop:min-h-[175px] text-center bg-noise bg-no-repeat bg-cover bg-center"
                    style={{
                      opacity: isInView ? 1 : 0,
                      transition: "all .5s linear 1.45s",
                    }}
                  >
                    <p className="text-sm font-normal leading-[24px] text-white/75 capitalize">
                      Inflectiv provides a fully {" "}
                      <span className="text-white text-medium">
                        decentralized computation and data processing network
                      </span>{" "}
                      for all Web 3 AI applications.

                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-8 md:pb-8 laptop:p-0 md:order-2 w-full h-full mx-auto">
                <div
                  className="relative w-full h-full flex items-center justify-center z-[2] min-h-[350px] laptop:min-h-[500px] mx-auto"
                  style={{
                    opacity: isInView ? 1 : 0,
                    transition: "all .5s linear 1.60s",
                  }}
                >
                  {/* <img
                    src={process.env.REACT_APP_S3_ASSETS_URL + "network.webp"}
                    alt=""
                  /> */}

                  <SplineViewer url="https://prod.spline.design/cxmCHl6XBtC5r8jn/scene.splinecode" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default NetworkAndNodes;
